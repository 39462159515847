//new value for select
import $ from "jquery";

//require('../../Modules/Dictionaries/Resources/assets/js/ee_dictionaries_component');

$(document).on('change', 'select.select_with_add', function () {
    let $valueTrigger = $(this).data('select_with_add');
    let $value = $(this).find('option:selected').text();
    if ($valueTrigger) {
        let $id = $(this).attr('id');
        if ($valueTrigger && $valueTrigger === $value) {
            $('#' + $id + '_new').show();
        } else {
            $('#' + $id + '_new').hide();
        }
    }
});
