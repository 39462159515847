require('./bootstrap');
import $ from 'jquery';

window.$ = window.jQuery = $;

require('./lightgallery-all.min');

$(document).ready(function () {
    //close modal on click
    $(document).mousedown(function (e) {
        let block = $(".modal-content");
        if (block.length > 0) {
            if (!block.is(e.target) && block.has(e.target).length === 0) {
                $('#modal-window').hide();
            }
        }
    });

    $(document).on('click', '.accordion-title, .accordion-checkbox-title', function () {
        $(this).toggleClass("active").next().slideToggle(500);
        // $('.accordion .accordion-title').not($(this)).removeClass("active").next().slideUp(500);
    });

    function checkDonorsError() {
        $('.accordion').each(function (i, e) {
            let counter = $(this).find('.accordion-content .error_form_element').length;
            if (counter > 0) {
                $(this).find('.error-count').html(counter);
                $(this).find('.error-count').show();
            }
        });
    }

    if ($('#donor-create-edit').length) {
        setInterval(checkDonorsError, 2000);
    }

    /*-- required label ---*/
    const formAdmin = $('.form-admin');
    let formAdminInput = $('.form-admin input:required');
    let formAdminSelect = $('.form-admin select:required');
    let arrForm = [formAdminInput, formAdminSelect];
    if (formAdmin.length) {
        for (let elem of arrForm) {
            elem.each(function () {
                $(this).prev('label').addClass('required');
            })
        }
    }

    /*--- select all checkbox ---*/
    $('#check-all').click(function (event) {
        if (this.checked) {
            // Iterate each checkbox
            $('.item-checkbox').each(function () {
                this.checked = true;
            });
        } else {
            $('.item-checkbox').each(function () {
                this.checked = false;
            });
        }

        let $groupOperationArray = [];
        $('.item-checkbox:checked').each(function () {
            $groupOperationArray.push($(this).val());
        });

        Livewire.emit('setGroupOperation', $groupOperationArray);
    });

    $('input').each(function (e) {
        if ($(this).attr('readonly')) {
            $(this).addClass('readonly');
        }
    })

    /*--- photogall ----*/
    $('.photogallery').lightGallery({
        selector: '.photo-item a',
        thumbnail: true,
        animateThumb: true,
        showThumbByDefault: true,
        toogleThumb: true,
        download: false,
        share: false,
    });

    /* to top */
    $(document).on('click', '.to-top', function () {
        $('.page').animate({scrollTop: 0}, 700);
        return false;
    });

    $(document).on('change', '#onlyMyDonors', function () {
        let donorsTitle = 'Donors';
        if ($(this).is(':checked')) {
            donorsTitle = 'My Donors';
        }
        $('#main_header').text(donorsTitle);
        $('head title').text(donorsTitle);
    });


    $(document).on('click', '.asideToggle', function () {
        $('.aside').toggleClass('translate-x-0');
    });

    $(document).on('click', '#filterBtn, #filterClose', function () {
        $('#filterAside').toggleClass('filter-show');
    });


});


Livewire.on('DonorCreateAdd', () => {
    checkDonorsError();
})

require('../../Modules/Dictionaries/Resources/assets/js/ee_dictionaries_component');
